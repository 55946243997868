import { useRef, useState, useEffect } from "react";
import { env } from "../../lib/env";
import Cookies from "js-cookie";
import { addMinutes } from "date-fns";
import { COOKIE_KEY, IFRAME_MESSAGES } from "../../lib/constants";

export default function IFrameAichat(props: {
  url: string;
  frameId: string;
  orgId: string;
  apiKey: string;
}) {
  const IRef = useRef<HTMLIFrameElement | null>(null);
  const [customClass, setCustomClass] = useState<string>(
    "ai-chat-iframe-frame-size-closed-with-welcome-msg",
  );
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { url, frameId, orgId, apiKey } = props;

  useEffect(() => {
    window.addEventListener("message", MessageEvent);
    if (Cookies.get(`welcome-container-${orgId}-${apiKey}`) === "false")
      setCustomClass("ai-chat-iframe-frame-size-closed");

    return () => {
      window.removeEventListener("message", MessageEvent);
    };
  }, []);

  const MessageEvent = (event: MessageEvent) => {
    const { isWidgetOpen, type, showWelcomeContainer } = event.data;
    const welcomeCookie = Cookies.get(`welcome-container-${orgId}-${apiKey}`);
    if (type === "aichat-chatbox-launch") {
      if (isWidgetOpen) {
        setCustomClass("ai-chat-iframe-frame-size-opened");
        setIsOpen(true);
      } else {
        if (showWelcomeContainer)
          setCustomClass("ai-chat-iframe-frame-size-closed-with-welcome-msg");
        else setCustomClass("ai-chat-iframe-frame-size-closed");
        setIsOpen(false);
      }
    }
    if (type === IFRAME_MESSAGES.setCookieData) {
      const {
        conversationMedium,
        widgetCookieKey,
        expiresIn,
        tokenValue,
        organizationId,
        cookieName,
      } = event.data;
      const isCookieExist = Cookies.get(
        `${COOKIE_KEY.tokenKey}-${organizationId}-${apiKey}`,
      );
      if (!isCookieExist) {
        Cookies.set(
          conversationMedium
            ? `${widgetCookieKey}-${conversationMedium}`
            : widgetCookieKey,
          tokenValue,
          {
            expires:
              conversationMedium === "audio"
                ? addMinutes(new Date(), 29) // The OpenAI API only allows 30 min of audio history
                : +expiresIn,
          },
        );
        Cookies.set(
          `${COOKIE_KEY.tokenKey}-${organizationId}-${apiKey}`,
          widgetCookieKey,
          {
            expires: +expiresIn,
          },
        );
        Cookies.set(COOKIE_KEY.serverKey, cookieName, {
          expires: +expiresIn,
        });
      }
      if (!welcomeCookie) {
        Cookies.set(`welcome-container-${organizationId}-${apiKey}`, "true", {
          expires: +expiresIn,
        });
      }
      iframePostMessage(IFRAME_MESSAGES.setWidgetCookie);
    }
    if (type === IFRAME_MESSAGES.disabledWelcomeMessage) {
      if (!welcomeCookie || welcomeCookie === "true") {
        Cookies.set(`welcome-container-${orgId}-${apiKey}`, "false");
        iframePostMessage(IFRAME_MESSAGES.setWidgetCookie);
      }
    }
  };

  const onLoadAction = () => {
    iframePostMessage(IFRAME_MESSAGES.onLoad);
  };

  const iframePostMessage = (message: string) => {
    const iframeWindow = IRef.current?.contentWindow;
    if (message === IFRAME_MESSAGES.onLoad) {
      setTimeout(() => {
        iframeWindow?.postMessage(
          {
            id: frameId,
            apiKey: apiKey,
            orgId: orgId,
          },
          "*",
        );
      }, 100);
    }
    if (message === IFRAME_MESSAGES.setWidgetCookie) {
      iframeWindow?.postMessage(
        {
          type: IFRAME_MESSAGES.cookieHasBeenSet,
          cookieList: Cookies.get(),
        },
        "*",
      );
    }
  };

  return (
    <iframe
      id={env.widgetSelectorId}
      className={`ai-chat-iframe-default ai-chat-iframe-dimension-custom ${isOpen ? "ai-chat-iframe-is-open" : "aichat-position-br"} ${customClass}`}
      src={`${url}/widget-frame.html`}
      data-org-id={orgId}
      data-api-key={apiKey}
      ref={IRef}
      onLoad={onLoadAction}
      allow="microphone"
      allowFullScreen
    ></iframe>
  );
}
