export const env = {
  mode: import.meta.env.MODE,
  socketUrl: (import.meta.env.VITE_API_URL ?? "") as string,
  socketTransports: (import.meta.env.VITE_SOCKET_TRANSPORTS ?? "") as string,
  apiUrl: `${(import.meta.env.VITE_API_URL ?? "") as string}/api/v2`,
  widgetApiKey: import.meta.env.VITE_PUBLIC_WIDGET_API_KEY,
  widgetOrgId: import.meta.env.VITE_PUBLIC_WIDGET_ORG_ID,
  deepgramApiKey: import.meta.env.VITE_PUBLIC_DEEPGRAM_API_KEY,
  heygenApiKey: import.meta.env.VITE_PUBLIC_HEYGEN_API_KEY,
  flagsmithEnvironmentId: import.meta.env.VITE_PUBLIC_FLAGSMITH_ENVIRONMENT_ID,
  flagsmithApi: import.meta.env.VITE_PUBLIC_FLAGSMITH_API,
  widgetUrl: import.meta.env.VITE_PUBLIC_WIDGET_URL ?? "",
  widgetSelectorId: import.meta.env.VITE_WIDGET_SELECTOR_ID_VALUE ?? "",
  viteModeIsProd: import.meta.env.MODE === "production",
  viteModeIsDev: import.meta.env.MODE === "development",
  isProd: import.meta.env.VITE_APP_ENV === "production",
  isDev: import.meta.env.VITE_APP_ENV === "development",
  sentryDsn: import.meta.env.VITE_PUBLIC_SENTRY_DSN,
  environment: import.meta.env.VITE_APP_ENV,
};
