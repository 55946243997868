import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { WidgetSelectorAttributes } from "../lib/constants";
import "../iframe.css";

import { env } from "../lib/env";
import IFrameAichat from "./component/iframe";

class WidgetFrame {
  private root: HTMLElement | null = null;

  constructor() {
    if (env.mode === "production") {
      this.init();
    }
  }

  async init() {
    let apiKey: string | null = null;
    let organizationId: string | null = null;

    const scriptElement = document.getElementById(WidgetSelectorAttributes.id);
    if (!scriptElement) throw new Error("AI Chat Script not mounted");

    if (env.viteModeIsProd) {
      // Check if elements are valid
      apiKey = scriptElement.getAttribute(
        WidgetSelectorAttributes.apiKeyAttribute,
      );
      organizationId = scriptElement.getAttribute(
        WidgetSelectorAttributes.orgIdAttribute,
      );
    } else {
      apiKey = env.widgetApiKey;
      organizationId = env.widgetOrgId;
    }

    if (!apiKey || !organizationId)
      throw new Error(
        `Invalid Configuration. Make sure you have ${WidgetSelectorAttributes.apiKeyAttribute} and ${WidgetSelectorAttributes.orgIdAttribute} in your script tags`,
      );

    if (!this.root) {
      this.root = document.createElement("div");
      this.root.id = "ai-chat-frame-container";
      this.root.className =
        "fixed bottom-5 right-5 sm:bottom-0 sm:right-0 z-[9999]";
      document.body.appendChild(this.root);
    }

    createRoot(this.root).render(
      <StrictMode>
        <IFrameAichat
          url={env.widgetUrl}
          frameId={WidgetSelectorAttributes.id}
          apiKey={apiKey}
          orgId={organizationId}
        />
      </StrictMode>,
    );
  }

  destroy() {
    if (this.root) {
      this.root.remove();
      this.root = null;
    }
  }
}

const widgetFrame = new WidgetFrame();

export default widgetFrame;

if (typeof window !== "undefined") {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).AIChatWidgetFrame = widgetFrame;
}

if (env.viteModeIsDev) {
  widgetFrame.init();
}
