export const HeaderKeys = {
  apiKey: "x-api-key",
  organizationId: "x-tenant-id",
};

export const WidgetSelectorAttributes = {
  apiKeyAttribute: "data-api-key",
  orgIdAttribute: "data-org-id",
  id: "ai-chat-widget",
};

export const COOKIE_KEY = {
  tokenKey: "aichat-widget-cookie-key",
  serverKey: "aichat-widget-server-cookie-key",
};

export const IFRAME_MESSAGES = {
  cookieHasBeenSet: "cookie-has-been-set",
  setCookieData: "set-cookie-data",
  disabledWelcomeMessage: "disabled-welcome-message",
  onLoad: "onload",
  setWidgetCookie: "set-widget-cookie",
  launchWidgetFromExternal: "launch-widget-from-external",
};
